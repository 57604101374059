<script setup lang="ts">
import { ArrowRightIcon } from '@heroicons/vue/20/solid'
import { Link } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
    <section class="bg-white">
        <div class="pt-32 overflow-hidden sm:pt-14">
            <div class="bg-primary-800">
                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div class="relative pt-48 pb-16 sm:pb-24">
                        <div>
                            <h2
                                id="sale-heading"
                                class="text-4xl font-bold tracking-tight text-white md:text-5xl"
                            >
                                {{ t('pages.home.outlet.title') }}
                                <br>
                                {{ t('pages.home.outlet.description') }}
                            </h2>
                            <div class="mt-6 text-p1">
                                <Link
                                    :href="route('shop.outlet')"
                                    class="inline-flex items-center font-medium text-white group"
                                >
                                {{ t('pages.home.outlet.cta') }}
                                <ArrowRightIcon class="w-4 h-4 ml-1 transition-transform duration-300 transform group-hover:translate-x-0.5" />
                                </Link>
                            </div>
                        </div>

                        <div class="absolute transform -translate-x-1/2 -top-32 left-1/2 sm:top-6 sm:translate-x-0">
                            <div class="flex ml-24 space-x-6 min-w-max sm:ml-3 lg:space-x-8">
                                <div class="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                                    <div class="flex-shrink-0">
                                        <img
                                            class="object-cover w-64 h-64 rounded-lg lazyload md:h-72 md:w-72"
                                            sizes="(max-width: 1152px) 100vw, 1152px"
                                            data-srcset="
                                                /images/sections/outlet/outlet-1_w256.jpg 256w,
                                                /images/sections/outlet/outlet-1_w590.jpg 590w,
                                                /images/sections/outlet/outlet-1_w837.jpg 837w,
                                                /images/sections/outlet/outlet-1_w1036.jpg 1036w,
                                                /images/sections/outlet/outlet-1_w1111.jpg 1111w,
                                                /images/sections/outlet/outlet-1_w1152.jpg 1152w"
                                            data-src="/images/sections/outlet/outlet-1_w1152.jpg"
                                            alt="Earrings Outlet"
                                        >
                                    </div>

                                    <div class="flex-shrink-0 mt-6 sm:mt-0">
                                        <img
                                            class="object-cover w-64 h-64 rounded-lg lazyload md:h-72 md:w-72"
                                            sizes="(max-width: 1152px) 100vw, 1152px"
                                            data-srcset="
                                                /images/sections/outlet/outlet-2_w256.jpg 256w,
                                                /images/sections/outlet/outlet-2_w559.jpg 559w,
                                                /images/sections/outlet/outlet-2_w779.jpg 779w,
                                                /images/sections/outlet/outlet-2_w969.jpg 969w,
                                                /images/sections/outlet/outlet-2_w1072.jpg 1072w,
                                                /images/sections/outlet/outlet-2_w1152.jpg 1152w"
                                            data-src="/images/sections/outlet/outlet-2_w1152.jpg"
                                            alt="Pendants Outlet"
                                        >
                                    </div>
                                </div>

                                <div class="flex space-x-6 sm:-mt-20 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                                    <div class="flex-shrink-0">
                                        <img
                                            class="object-cover w-64 h-64 rounded-lg lazyload md:h-72 md:w-72"
                                            sizes="(max-width: 1152px) 100vw, 1152px"
                                            data-srcset="
                                                /images/sections/outlet/outlet-3_w256.jpg 256w,
                                                /images/sections/outlet/outlet-3_w415.jpg 415w,
                                                /images/sections/outlet/outlet-3_w518.jpg 518w,
                                                /images/sections/outlet/outlet-3_w560.jpg 560w,
                                                /images/sections/outlet/outlet-3_w818.jpg 818w,
                                                /images/sections/outlet/outlet-3_w884.jpg 884w,
                                                /images/sections/outlet/outlet-3_w981.jpg 981w,
                                                /images/sections/outlet/outlet-3_w1044.jpg 1044w,
                                                /images/sections/outlet/outlet-3_w1124.jpg 1124w,
                                                /images/sections/outlet/outlet-3_w1152.jpg 1152w"
                                            data-src="/images/sections/outlet/outlet-3_w1152.jpg"
                                            alt="Rings Outlet"
                                        >
                                    </div>

                                    <div class="flex-shrink-0 mt-6 sm:mt-0">
                                        <img
                                            class="object-cover w-64 h-64 rounded-lg lazyload md:h-72 md:w-72"
                                            sizes="(max-width: 1152px) 100vw, 1152px"
                                            data-srcset="
                                                /images/sections/outlet/outlet-4_w256.jpg 256w,
                                                /images/sections/outlet/outlet-4_w531.jpg 531w,
                                                /images/sections/outlet/outlet-4_w751.jpg 751w,
                                                /images/sections/outlet/outlet-4_w929.jpg 929w,
                                                /images/sections/outlet/outlet-4_w1110.jpg 1110w,
                                                /images/sections/outlet/outlet-4_w1152.jpg 1152w"
                                            data-src="/images/sections/outlet/outlet-4_w1152.jpg"
                                            alt="Bracelets Outlet"
                                        >
                                    </div>
                                </div>

                                <div class="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                                    <div class="flex-shrink-0">
                                        <img
                                            class="object-cover w-64 h-64 rounded-lg lazyload md:h-72 md:w-72"
                                            sizes="(max-width: 1152px) 100vw, 1152px"
                                            data-srcset="
                                                /images/sections/outlet/outlet-5_w256.jpg 256w,
                                                /images/sections/outlet/outlet-5_w484.jpg 484w,
                                                /images/sections/outlet/outlet-5_w639.jpg 639w,
                                                /images/sections/outlet/outlet-5_w819.jpg 819w,
                                                /images/sections/outlet/outlet-5_w951.jpg 951w,
                                                /images/sections/outlet/outlet-5_w1094.jpg 1094w,
                                                /images/sections/outlet/outlet-5_w1152.jpg 1152w"
                                            data-src="/images/sections/outlet/outlet-5_w1152.jpg"
                                            alt="Gold Outlet"
                                        >
                                    </div>

                                    <div class="flex-shrink-0 mt-6 sm:mt-0">
                                        <img
                                            class="object-cover w-64 h-64 rounded-lg lazyload md:h-72 md:w-72"
                                            sizes="(max-width: 1152px) 100vw, 1152px"
                                            data-srcset="
                                                /images/sections/outlet/outlet-6_w256.jpg 256w,
                                                /images/sections/outlet/outlet-6_w532.jpg 532w,
                                                /images/sections/outlet/outlet-6_w725.jpg 725w,
                                                /images/sections/outlet/outlet-6_w915.jpg 915w,
                                                /images/sections/outlet/outlet-6_w1040.jpg 1040w,
                                                /images/sections/outlet/outlet-6_w1129.jpg 1129w,
                                                /images/sections/outlet/outlet-6_w1152.jpg 1152w"
                                            data-src="/images/sections/outlet/outlet-6_w1152.jpg"
                                            alt="Silver Outlet"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
